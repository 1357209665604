import PropTypes from "prop-types";

import styles from "@platformAssets/components/InputGlobal.module.scss";

const InputGlobal = ({
  animatePlaceholder = false,
  autoComplete = "off",
  className = null,
  disabled = false,
  error = false,
  errorText = null,
  extraIconSrc = null,
  handleChange,
  handleClickExtraIcon = null,
  handleClickLeftIcon = null,
  handleClickRightIcon = null,
  id = null,
  leftIconSrc = null,
  name,
  onBlur = null,
  onFocus = null,
  onClick = null,
  passwordShowIcon = null,
  placeholder = null,
  refValue,
  rightIconSrc = null,
  type = "text",
  value = null,
  warningText = null,
}) => {
  const stylesValues =
    disabled && errorText
      ? `${styles.disabled}`
      : disabled && warningText
        ? `${styles.disabled}`
        : disabled && error
          ? `${styles.disabled} ${styles.errorDisabled}`
          : disabled
            ? `${styles.disabled} `
            : error || errorText
              ? `${styles.error}`
              : "";

  return (
    <div
      className={`${styles.inputWrapper} ${
        animatePlaceholder ? styles.inputAnimated : ""
      } ${className ?? ""} ${leftIconSrc ? styles.hasIconLeft : ""} ${
        rightIconSrc ? styles.hasIconRight : ""
      } ${extraIconSrc ? styles.hasIconExtra : ""}`}
    >
      <div className={styles.inputWrapperRelative}>
        {leftIconSrc && (
          <div
            className={`${styles.iconLeft} ${
              handleClickLeftIcon ? styles.iconLeftActive : ""
            }`}
            onClick={handleClickLeftIcon ?? null}
          >
            <div className={styles.img}>{leftIconSrc}</div>
          </div>
        )}
        <input
          className={stylesValues}
          type={type}
          name={name}
          id={id ?? `${name}Id`}
          autoComplete={autoComplete}
          placeholder={placeholder ?? ""}
          disabled={disabled}
          value={value ?? ""}
          onChange={handleChange}
          ref={refValue}
          onFocus={() => {
            if (typeof onFocus === "function") {
              onFocus();
            }
          }}
          onBlur={() => {
            if (typeof onBlur === "function") {
              onBlur();
            }
          }}
          onClick={() => {
            if (typeof onClick === "function") {
              onClick();
            }
          }}
        />
        {animatePlaceholder && (
          <label
            htmlFor={id ?? `${name}Id`}
            className={styles.inputAnimatedPlaceholder}
          >
            {placeholder}
          </label>
        )}

        {extraIconSrc && (
          <div
            className={`${styles.iconExtra} ${
              handleClickExtraIcon ? styles.iconExtraActive : ""
            }`}
            onClick={handleClickExtraIcon}
          >
            <div className={styles.img}>{extraIconSrc}</div>
          </div>
        )}
        {rightIconSrc && (
          <div
            className={`${styles.iconRight} ${
              handleClickRightIcon ? styles.iconRightActive : ""
            } ${passwordShowIcon ? styles.iconShow : ""}`}
            onClick={handleClickRightIcon ?? null}
          >
            <div className={styles.img}>{rightIconSrc}</div>
          </div>
        )}
      </div>
      <p className={styles.errorText}>{errorText}</p>
      <p className={styles.errorWarning}>{warningText}</p>
    </div>
  );
};

InputGlobal.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  animatePlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  errorText: PropTypes.string,
  warningText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  refValue: PropTypes.any,
  leftIconSrc: PropTypes.object,
  rightIconSrc: PropTypes.object,
  handleClickLeftIcon: PropTypes.func,
  handleClickRightIcon: PropTypes.func,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  passwordShowIcon: PropTypes.bool,
};

export default InputGlobal;
